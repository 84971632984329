
export default function filterSeoSchema(post) {
	let frontendUrl = '';
	//console.log('seo scheme before filtering', post.seo.schema.raw)
	//replace url of opengraphImage if it's set
	if (post?.seo?.opengraphImage?.sourceUrl) {
	  frontendUrl = getLiveUrl(post.seo.opengraphImage.sourceUrl);
	  post.seo.schema.raw = post.seo.schema.raw.replaceAll(post.seo.opengraphImage.sourceUrl, frontendUrl+post.seo.opengraphImage.localFile.publicURL);
	  post.seo.opengraphImage.sourceUrl = post.seo.opengraphImage.sourceUrl = frontendUrl+post.seo.opengraphImage.localFile.publicURL;
	}
	if (post?.seo?.twitterImage?.sourceUrl) {		
	  frontendUrl = getLiveUrl(post.seo.twitterImage.sourceUrl);
	  post.seo.schema.raw = post.seo.schema.raw.replaceAll(post.seo.twitterImage.sourceUrl, frontendUrl+post.seo.twitterImage.localFile.publicURL);
	  post.seo.twitterImage.sourceUrl = post.seo.twitterImage.sourceUrl = frontendUrl+post.seo.twitterImage.localFile.publicURL;
	}
	
	//console.log('seo after filtering', post.seo)
	
	return post;
}

/**
 * Given a path on the CMS, this returns the hostname for the live site
 */
const getLiveUrl = function(url) {
	let host = extractHostname(url);
	switch (host) {
		case 'cms.dev.landbay.co.uk':
			return 'http://localhost:8000';
		case 'cms.uatenv.landbay.co.uk':
			return 'https://www.uatenv.landbay.co.uk';
		default:
			return 'https://landbay.co.uk';
	}
}

const extractHostname = function(url) {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}