import React from "react"
import { graphql, Link } from "gatsby"
import Layout from '../components/Layout' 
import Hero from '../components/widgets/Hero' 
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PostPreview from '../components/PostPreview' 
import BackIcon from "../assets/svg/inline/ic_chevron-down.svg"
import DownloadIcon from "../assets/svg/inline/ic_download.svg"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import filterSeoSchema from '../utils/filterSeoSchema' 

export default function Post({ data }) {
  const post = data.allWpPost.nodes[0]
  const author = post?.author?.node;

  /**
   * Print the current screen
   */
  const print = function() {
	if (window) {
		window.print();
	}
  }

  return (
    <Layout className="post">	
      <Seo post={filterSeoSchema(post)} />
		<div className="page-widgets">
			<Hero widget={{layout : 'mini'}} />
		</div>
        <article className="internal">
		  <header>
			  {
				post.categories?.nodes?.length
				? <p className="categories">{Array.prototype.map.call(post.categories.nodes, function(item) { return item.name; }).join(", ")}</p>
				: null
			  }		
            <h1>{post.title}</h1>
			<p className="date">{post.date}</p>
			<div className="author">
				{
				  author?.authors?.userProfilePicture?.localFile
				  ? <GatsbyImage image={getImage(author.authors.userProfilePicture.localFile)} alt={author.name} />
				  : null
			    }
				<span className="author-details">				
					{
					  author?.name
					  ? <span className="author-name">{author.name}</span>
					  : null
					}			
					{
					  author?.authors?.userJobTitle
					  ? <span className="author-job-title">{author.authors.userJobTitle}</span>
					  : null
					}
				</span>
			</div>
			<p className="back"><Link to="/blog/"><BackIcon />Back</Link></p>
			<p className="print"><button className="plain" onClick={print}>Print article<DownloadIcon /></button></p>
		  </header>
          <div className="content landbay-article-styles" dangerouslySetInnerHTML={{ __html: post.content }} />
	      {post.tags?.nodes?.length>0 && (		
		    <footer>
				<p>
				  {post.tags.nodes.map((tag, i) => (	
					<span key={`post${i}`}>{tag.name}</span>
		          ))}
				</p>
		    </footer>
		  )}
        </article>
		{/*do we have some related articles?*/}
		{post.relatedArticles?.articleRelated?.length>0 && (
			<aside className="related">
			  <div className="internal">
				<h2>Related articles</h2>
				<div className="articles">
				    {post.relatedArticles.articleRelated.map((article, i) => (
					  <PostPreview key={`article${i}`} post={article} />
				    ))}
				</div>
			  </div>
			</aside>
		)}
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allWpPost(filter: { id: { eq: $id } }) {
      nodes {
	    categories {
          nodes {
            name
          }
        }
        title
        date(formatString: "D MMMM YYYY")
        content
        ...PostSEOFragment
	    tags {
	      nodes {
	        name
	      }
	    }
	 	author {
	      node {
	        name
	        authors {
	          userJobTitle
	          userProfilePicture {
	            localFile {
				  childImageSharp {
			        gatsbyImageData(
					  sizes: "54px",
					  breakpoints: [106]
			        )
			      }
		        }
	          }
	        }
	      }
	    }
		relatedArticles {
	      articleRelated {
	        ... on WpPost {
	          ...PostFragment
	        }
	      }
	    }
      }
    }
  }
`